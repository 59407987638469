<template>
  <div class="dashboard">
    <span>MS Ecosystem Admin</span>
  </div>
</template>

<script>
export default {
  name: 'dashboard',
}
</script>

<style lang="scss">
  .row-equal .flex {
    .va-card {
      height: 100%;
    }
  }

  .dashboard {
    .va-card {
      margin-bottom: 0 !important;
    }
  }
</style>
